var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mb-16 pb-16 justify-space-between"},[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(_vm.addresses)?_c('div',{staticClass:"row"},[_c('h5',{staticClass:"text-uppercase"},[_vm._v("Click to select")]),_vm._l((_vm.addresses),function(addr){return _c('div',{key:addr.id,staticClass:"col-lg-6 col-sm-6"},[_c('div',[_c('label',{staticClass:"card-radio-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAddress),expression:"selectedAddress"},{name:"b-modal",rawName:"v-b-modal.modal-address",modifiers:{"modal-address":true}}],staticClass:"card-radio-input",attrs:{"type":"radio","name":"pay-method","id":addr.id,"hidden":""},domProps:{"value":addr,"checked":_vm._q(_vm.selectedAddress,addr)},on:{"click":function($event){return _vm.selectrec(
                addr.id,
                addr.index,
                addr.address__region__region,
                addr.address__pickup_location,
                addr.postal_code,
                addr.customer__user__first_name,
                addr.customer__user__last_name,
                addr.phone,
                addr.other_phone,
                addr.address_label,
                addr.default_address
              )},"change":function($event){_vm.selectedAddress=addr}}}),_c('span',{staticClass:"card-radio text-wrap"},[_c('i',{staticClass:"uil uil-postcard text-warning float-end h2"}),_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(addr.address_label))]),_c('v-divider'),_c('h5',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(addr.user__first_name)+" "+_vm._s(addr.user__last_name)+" ")]),_c('p',[_c('span',[_vm._v(_vm._s(addr.address__pickup_location))]),_c('br'),_c('span',[_vm._v(_vm._s(addr.address__region__region))]),_c('br'),_c('span',[_vm._v(_vm._s(addr.phone))])])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-address",modifiers:{"modal-address":true}}],staticClass:"btn btn-warning text-white",on:{"click":function($event){return _vm.edit(
                    addr.id,
                    addr.index,
                    addr.address__region__region,
                    addr.address__pickup_location,
                    addr.zip_code,
                    addr.user__first_name,
                    addr.user__last_name,
                    addr.phone,
                    addr.other_phone,
                    addr.delivery_address,
                    addr.default_address
                  )}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-address",modifiers:{"modal-address":true}}],staticClass:"btn btn-warning text-white",on:{"click":function($event){return _vm.deleterec(addr.id, addr.address__pickup_location)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])])])])])])})],2):_vm._e(),_c('div',{staticClass:"card"},[_c('b-modal',{attrs:{"title":_vm.modaltitle,"size":"lg","id":"modal-address"}},[_c('addAddress',{attrs:{"editmode":_vm.editmode,"selectmode":_vm.selectmode,"modaltitle":_vm.modaltitle,"id":_vm.myid,"index":_vm.myindex,"selectedCity":_vm.selectedCity,"selectedTown":_vm.selectedTown,"postal_code":_vm.postal_code,"phone":_vm.phone,"other_phone":_vm.other_phone,"address_label":_vm.address_label,"default_address":_vm.default_address},on:{"getuserAddress":function($event){return _vm.getuserAddress()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }